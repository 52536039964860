import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";
import { PesquisaSatisfacao } from "./components/PesquisaSatisfacao";
import { ResultadoPesquisa } from "./components/ResultadoPesquisa";

const AppRoutes = [
    {

        element: <Home />
    },
    {
        path: '/counter',
        element: <Counter />
    },
    {
        path: '/fetch-data',
        element: <FetchData />
    },
    {
        index: true,
        element: <PesquisaSatisfacao />
    },
    {
        path: '/resultado-pesquisa',
        element: <ResultadoPesquisa />
    }
];

export default AppRoutes;
