import React, { Component} from 'react';
import Quadros from './Charts/Quadros';
import DateFilter from './DataFilter';
import Card from 'react-bootstrap/Card';
import moment from 'moment';
import 'moment/locale/pt-br';
import Loader from './Loader';

export default function QuadroPonto({texto, value, cor}) {

    return <div className={`quadro ${cor}`} style={{ width: '61px', height: '70px' }}  >
        <span className="valor" style={{ fontSize: '18px' }}>{value}</span>
        <span className="texto" style={{ marginTop: '190%', color: 'black', fontSize: '11px' }}> {texto}</span>
    </div>;
}

export class ResultadoPesquisa extends Component {
    static displayName = ResultadoPesquisa.name;

    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
        this.state =
        {
            selectedValueCombo: 'Principal',            
            selectedValueFilial: 'Todos',
            respostas: {
                id: 0,
                totalRespostas: 0,
                respDetratores: 0,
                respNeutras: 0,
                respPromotores: 0,
                rercPromotores: 0.0,
                rercDetratores: 0.0,
                rercNeutros: 0.0,
                nps: 0.0,
                notasIndicacao: [],
                notasAtendimento: []
            },
            loading: true
        };

        this.PesquisarResultados = this.PesquisarResultados.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.renderResultados = this.renderResultados.bind(this);
  }

    async PesquisarResultados(data1, data2, selectedValueCombo, selectedValueFilial)
    {
        let filialResp = selectedValueFilial === '' || selectedValueFilial === undefined ? "Todos" : selectedValueFilial;
        let origemResp = selectedValueCombo === undefined || selectedValueCombo === '' ? "Todos" : selectedValueCombo;
        this.setState({ selectedValueCombo: selectedValueCombo, loading: true });

        //const response = await fetch('ResultadoPesquisa/InfoNPS?data1=' + { data1.toString() } + '&data2=' + { data2.toString() });
        const response = await fetch(`ResultadoPesquisa/InfoNPS?data1=${data1}&data2=${data2}&origemResposta=${origemResp}&filial=${filialResp}`);

        const data = await response.json();
        this.setState({ respostas: data,  loading: false });

        //this.setState({ respostas: data, loading: false });
    }

    componentDidMount() {
     
        this.populateResultatoData();
        
    }

    renderResultados() {

        const neutros = {
            texto: "Neutros",
            percentual: this.state.respostas.percNeutros,
            total: this.state.respostas.respNeutras
        };

        const promotores = {
            texto: "Promotores",
            percentual: this.state.respostas.percPromotores,
            total: this.state.respostas.respPromotores
        };

        const detratores = {
            texto: "Detratores",
            percentual: this.state.respostas.percDetratores,
            total: this.state.respostas.respDetratores
        };

        let pesquisa = this.state.loading ? <Loader></Loader> : "";
        
        return(

        //teste
            <>  
                <div>
                    <DateFilter evento={this} Pesquisar={this.PesquisarResultados} />
                </div>
                {pesquisa}
                <div className="">
                    <div>
                        <Card
                            border="success"
                            key="PR1"
                            style={{ width: '45rem' }}
                            className="mb-2">
                                <Card.Header className="CardHeader"> De acordo com a sua experiência de compra, de 0 a 10, o quanto você indicaria a Farma Débora para um parente ou amigo?</Card.Header>
                        </Card>
                    </div>
                </div>
                <div className="SquaresContainer">
                    <div className="SquaresColumn">
                        <Quadros neutros={neutros} promotores={promotores} detratores={detratores} totalrespostas={this.state.respostas.totalRespostas} />
                    </div>
                    <div className="SquaresColumn">
                        <div className="rectangleNps">
                            <span className="capScore">Score NPS:</span>
                        </div>

                        <div className="quadroNps">
                            <span className="valor">{this.state.respostas.nps}</span>
                        </div>
                    </div>
                </div>
                <div className="SquaresContainer">
                {
                        this.state.respostas.notasIndicacao.slice(0, 7).map((r, idxp) => (

                        <div key={'r11_' + idxp} className="SquaresColumn">
                            <QuadroPonto texto={r.nota} value={r.totalRespostas} cor="detratores" />
                        </div>
                    ))
                }
                {
                        this.state.respostas.notasIndicacao.slice(7, 9).map((r, idxp) => (

                            <div key={'r12_' + idxp } className="SquaresColumn">
                            <QuadroPonto texto={r.nota} value={r.totalRespostas} cor="neutros" />
                        </div>
                    ))
                }

                {
                        this.state.respostas.notasIndicacao.slice(9, 11).map((r, idxp) => (

                        <div key={'r13_' + idxp} className="SquaresColumn">
                            <QuadroPonto texto={r.nota} value={r.totalRespostas} cor="promotores" />
                        </div>
                    ))
                }

                </div>
                <br />
                <div className="SquaresContainer" style={{ marginTop: '2%' }}>
                    <div className="SquaresColumn">
                        <Card
                            border="success"
                            key="PR2"
                            style={{ width: '45rem' }}
                            className="mb-2">
                                <Card.Header className="CardHeader">Como foi o seu atendimento?</Card.Header>
                        </Card>

                    </div>
                </div>
                
                
                 <div className="SquaresContainer">
                    {
                        this.state.respostas.notasAtendimento.slice(0, 7).map((r, idxp) => (

                            <div key={'r21_' + idxp } className="SquaresColumn">
                                <QuadroPonto texto={r.nota} value={r.totalRespostas} cor="detratores" />
                            </div>
                        )) }
                    {
                        this.state.respostas.notasAtendimento.slice(7, 9).map((r, idxp) => (

                            <div key={'r22_' + idxp} className="SquaresColumn">
                                <QuadroPonto texto={r.nota} value={r.totalRespostas} cor="neutros" />
                            </div>
                        ))
                        }
                    {
                        this.state.respostas.notasAtendimento.slice(9, 11).map((r, idxp) => (

                            <div key={'r23_' + idxp} className="SquaresColumn">
                                <QuadroPonto texto={r.nota} value={r.totalRespostas} cor="promotores" />
                            </div>
                        ))}
                    

                </div>
             </>);
    }

    render() {
       // let contents = this.state.loading ? <Loader></Loader> : this.renderResultados();
        let contents = this.renderResultados();
        return <div className="d-flex flex-column justify-content-center align-items-center">
            {contents}
        </div>
    }

    async populateResultatoData() {

        const today = new Date();
        const startDate = new Date(today.getFullYear(), 0, 1); // 1 de janeiro do ano atual
        const endDate = today;

        const formattedStartDate = moment(startDate).format('DD-MM-YYYY');
        const formattedEndDate = moment(endDate).format('DD-MM-YYYY');

        await this.PesquisarResultados(formattedStartDate, formattedEndDate, this.state.selectedValueCombo);
    }

}