import React, { useState, useEffect } from 'react';
import { Modal, ProgressBar } from 'react-bootstrap';
import './MensagemSucesso.css';

const MensagemSucesso = ({ mensagem, UpdateEvento, intervalo }) => {
    const [showModal, setShowModal] = useState(true);
    const [progress, setProgress] = useState(100);
    const [timer, setTimer] = useState(null);

    useEffect(() => {
        var tempointer = intervalo === undefined ? 5000 : intervalo;
        const intervalId = setInterval(() => {
            setProgress((prevProgress) => prevProgress - 1);
        }, 40);

        setTimer(intervalId)

        setTimeout(() => {
            clearInterval(intervalId);
            setShowModal(false);
            UpdateEvento();
        }, tempointer);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const handleClose = () => {
        clearInterval(timer);
        setShowModal(false);
        UpdateEvento();
    };

    return (
        <Modal dialogClassName="modal-2" show={showModal} onHide={() => handleClose()}>
            <Modal.Header closeButton >
                <Modal.Title >Farma Débora</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <span>{mensagem}</span>
            </Modal.Body>
            <ProgressBar className="pogress-1" now={progress} />
        </Modal>
    );
};

export default MensagemSucesso;
