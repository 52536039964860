import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DataFilter.css';
import moment from 'moment';
import 'moment/locale/pt-br';
import Button from 'react-bootstrap/Button';
import Combobox from './Combobox';

function DateFilter(props, eventoState) {
    const today = new Date();
    const initialStartDate = new Date(today.getFullYear(), 0, 1); // 1º de janeiro do ano atual
    const initialEndDate = today;

    const [selectedValueCombo, setSelectedValueCombo] = useState('Principal');
    const [selectedValueFilial, setSelectedValueFilial] = useState('Principal');
    const [startDate, setStartDate] = useState(initialStartDate);
    const [endDate, setEndDate] = useState(initialEndDate);
    const OrigemLancamentos = [{ value: 'Principal', label: 'Loja' }, { value: 'WhatsApp', label: 'WhatsApp' }];
    const Filiais = [{ value: 'Matriz', label: 'Teresópolis' }, { value: 'SaoJose', label: 'São José' }, { value: 'Guapi', label: 'Guapimirim' }];

    const handleStartDateChange = date => {
        setStartDate(date);
    };

    const handleEndDateChange = date => {
        setEndDate(date);
    };

    const handleSelectedValueChange = value => {
        setSelectedValueCombo(value);
    }

    const handleSelectedValueFilialChange = value => {
        setSelectedValueFilial(value);
    }

    const handleFilter = () => {
        const formattedStartDate = moment(startDate).format('DD-MM-YYYY');
        const formattedEndDate = moment(endDate).format('DD-MM-YYYY');
        props.Pesquisar(formattedStartDate, formattedEndDate, selectedValueCombo, selectedValueFilial);        
    };

    return (
        <div className="date-filter-container">
            <div className="date-filter-input-container">
                <label htmlFor="cmbFilial" className="date-filter-label">
                    Filial:
                </label>
                <Combobox id="cmbFilial" textonull="Ver todas" options={Filiais} selectedValue={selectedValueFilial} onSelectedValueChange={handleSelectedValueFilialChange} ></Combobox>
            </div>
            <div className="date-filter-input-container">
                <label htmlFor="cmbOrigem" className="date-filter-label">
                    Origem pesquisa:
                </label>
                <Combobox id="cmbOrigem" textonull="Ver todas" options={OrigemLancamentos} selectedValue={selectedValueCombo} onSelectedValueChange={handleSelectedValueChange} ></Combobox>
            </div>
            <div className="date-filter-input-container">
                <label htmlFor="start-date" className="date-filter-label">
                    Data inicial:
                </label>
                <DatePicker 
                    id="start-date"
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={handleStartDateChange}
                />
            </div>
            <div className="date-filter-input-container" style={{ marginLeft: '9px' }}>
                <label htmlFor="end-date" className="date-filter-label">
                    Data final:
                </label>
                <DatePicker
                    id="end-date"
                    dateFormat="dd/MM/yyyy"
                    selected={endDate}
                    onChange={handleEndDateChange}
                />
            </div>
            <Button onClick={handleFilter} variant="success" className="date-filter-button">
                Buscar
            </Button>
        </div>
    );
}

export default DateFilter;
