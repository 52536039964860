import React from "react";
import "../ResultadoPesquisa.css";

function Quadro({ cor, texto, value, estiloExtra }) {

    return <div className={`quadro ${cor}`} style={estiloExtra}>
        <span className="valor">{value} </span>
    </div>;
}

function Retangulo({ cor, texto, value }) {

    return <div className={`rectangle ${cor}`}>
        <span className="textoRetangulo">{texto} </span>
    </div>;

}

export default function Quadros({ neutros, promotores, detratores, totalrespostas }) {
    const comprimento = "comprimento";
    const formatter = new Intl.NumberFormat('pt-br', {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        decimal: '.',
        percent: '.',
        grouping: ''
    });

    return (
        <div className="SquaresContainer">
            <div className="SquaresColumn">
                <Retangulo cor="promotores" texto="Promotores"></Retangulo>
                <Retangulo cor="neutros" texto="Neutros"></Retangulo>
                <Retangulo cor="detratores" texto="Detratores"></Retangulo>
                <Retangulo cor="cinza" texto="Total de respostas"></Retangulo>
            </div>
            <div className="SquaresColumn">
                <Quadro cor="promotores" value={formatter.format(promotores.percentual/100)} />
                <Quadro cor="neutros" value={formatter.format(neutros.percentual /100)} />
                <Quadro cor="detratores" value={formatter.format(detratores.percentual/100)} /> 
            </div>
            <div className="SquaresColumn">
                <Quadro cor="promotores" value={promotores.total} />
                <Quadro cor="neutros" value={neutros.total} />
                <Quadro cor="detratores" value={detratores.total} />
                <Quadro cor="cinza" value={totalrespostas}  />
            </div>
          
        </div>
    );
}

