import React, { Component, useState } from 'react';
import Button from 'react-bootstrap/Button';
import './PesquisaSatisfacao.css';
import Card from 'react-bootstrap/Card';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Loader from './Loader';
import MensagemSucesso from './MensagemSucesso';
import MensagemAviso from './MensagemAviso';
import queryString from 'query-string';

export class PesquisaSatisfacao extends Component {
    static displayName = PesquisaSatisfacao.name;   

    constructor(props) {
        super(props);
        this.state =
        {
            perguntas: [],
            resposta1: -1,
            resposta2: -1,
            loading: true,
            mensagemEnviada: false,
            mensagemAvisoSelecao: false,
            parametrosURL: { pwhatsapp: 'false', pfmguapi: 'false', pfmsaojoserp: 'false' },
            Ambiente: "NaoInformado",
            isButtonFullScreen: true
        };

        this.UpdateTela = this.UpdateTela.bind(this);
        this.UpdateAvisoSelecao = this.UpdateAvisoSelecao.bind(this);
        this.enterFullscreen = this.enterFullscreen.bind(this);
        this.isParamWhatsApp = this.isParamWhatsApp.bind(this);
        this.isParamFarmaGuapi = this.isParamFarmaGuapi.bind(this);
        this.isParamFarmaSaoJose = this.isParamFarmaSaoJose.bind(this);
    }

    enterFullscreen() {
        const element = document.documentElement; // Elemento raiz (geralmente <html>) da página
        const requestFullscreen = element.requestFullscreen || element.mozRequestFullScreen || element.webkitRequestFullscreen || element.msRequestFullscreen;

        if (requestFullscreen) {
            requestFullscreen.call(element);
        }

        this.setState({ isButtonFullScreen: false })
    }
    componentDidMount() {
        if (window.location.search !== '')
            this.setState({ parametrosURL: queryString.parse(window.location.search.toLowerCase()) });

        this.populatePesquisaData();
    }

    onChangeResposta = (idx, idresposta) => {

        var idPergunta = idx.split("-")[1];
        if (idPergunta === '1') {
            this.setState({
                resposta1: idresposta
            });
        }
        else {
            this.setState({
                resposta2: idresposta
            });
        }

       // this.setState({});
       // this.setState({ perguntas: [], loading: false });
        return idresposta;
    };

    static renderPerguntas(bind, perguntas) {
        console.log(bind.state.Ambiente);
        return (
            <div className="">
                <div>
                    {perguntas.map((pergunta, idxp) => (
                        <div key={'pdiv' + idxp} className="col-lg-4 col-sm-12">
                            <br />
                            <Card
                                border="success"
                                key={'p' + idxp}
                                style={{ width: '40rem' }}
                                className="custom-card mb-2">
                            <Card.Header className="CardHeader"> {pergunta.textoPergunta}</Card.Header>
                                <Card.Body>
                                   
                                        {
                                            <ButtonGroup className="d-flex justify-content-center" id={'bg' + pergunta.id}>
                                            {
                                                pergunta.opcoesRespostas.sort((a, b) => a.id - b.id).map((radio, idx) =>
                                                    (
                                                        <ToggleButton
                                                            key={idx}
                                                            id={`radio-${radio.idPergunta}-${idx}`}
                                                            type="radio"
                                                             variant={
                                                                 (radio.id >= 0 && radio.id <= 6) ? 'danger' :
                                                                (radio.id >= 7 && radio.id <= 8) ? 'warning' :
                                                                    'success'
                                                             }
                                                        size="lg"
                                                        className=""
                                                            name={"radio" + radio.idPergunta + "-" + idx}                                                            
                                                            value={radio.idPergunta + '-' + radio.id}
                                                            checked={bind.state['resposta' + radio.idPergunta] === (radio.idPergunta + '-' + radio.id)}
                                                            onChange={(e) => bind.onChangeResposta(e.currentTarget.id, e.currentTarget.value)}
                                                           >
                                                            {radio.textoOpcao}
                                                        </ToggleButton>
                                                    ))
                                            }
                                            </ButtonGroup>


                                    }
                                </Card.Body>
                            </Card>                        
                            
                        </div>
                        ))}
                </div>
                {(bind.state.isButtonFullScreen && !bind.isParamWhatsApp()) && <Button type="button" name="btnFullScreen" variant="secondary" onClick={bind.enterFullscreen}>Entrar em Tela Cheia</Button>}
                <div style={{ display: 'flex', float: 'right' }}>
                    
                    <Button type="button" name="btnEnviar" variant="success" style={{ width: '120px' }}
                        onClick={() => bind.enviarResposta() }>Enviar</Button>
                </div>
            </div>);
    }

    UpdateTela() {
        if (this.isParamWhatsApp())
            window.location.href = 'https://farmadebora.com.br';
        else {
            this.setState({
                resposta1: -1,
                resposta2: -1,
                mensagemEnviada: false,
                mensagemAvisoSelecao: false
            });
            //window.location.reload();
        }
    }

    UpdateAvisoSelecao() {
        this.setState({ mensagemEnviada: false, mensagemAvisoSelecao: false });
    }

    render() {

        let contents = this.state.loading ? <Loader /> :
            PesquisaSatisfacao.renderPerguntas(this, this.state.perguntas);

        return (
            <div className="d-flex flex-column justify-content-center align-items-center">                
                {this.state.mensagemAvisoSelecao && <div> <MensagemAviso mensagem="Por favor, marque uma resposta antes de enviar!" titulo="Atenção!" UpdateEvento={this.UpdateAvisoSelecao} /> </div>}
                {this.state.mensagemEnviada && <div> <MensagemSucesso mensagem= "Farma Débora agradece. Suas escolhas foram computadas!" UpdateEvento={this.UpdateTela} /> </div>}
                {contents}           
            </div>
        );
    }

    isMobileDevice() {
        return /Android|iPhone|iPad|iPod|Windows Phone|BlackBerry/i.test(navigator.userAgent);
    }

    isParamWhatsApp() {
        return ('pwhatsapp' in this.state.parametrosURL) && (this.state.parametrosURL.pwhatsapp.toLowerCase() == "true");
    }

    isParamFarmaGuapi() {
        return ('pfmguapi' in this.state.parametrosURL) && (this.state.parametrosURL.pfmguapi.toLowerCase() == "true");
    }

    isParamFarmaSaoJose() {
        return ('pfmsaojoserp' in this.state.parametrosURL) && (this.state.parametrosURL.pfmsaojoserp.toLowerCase() == "true");
    }

    async populatePesquisaData() {
        const response = await fetch('PesquisaSatisfacao');

        const data = await response.json();
        const response2 = await fetch('PesquisaSatisfacao/GetInfoAmbiente');
        const confAmbiente = await response2.json();
        this.setState({ Ambiente: confAmbiente.ambiente, perguntas: data, loading: false });
    }


    async enviarResposta() {
        this.setState({ mensagemEnviada: false, mensagemAvisoSelecao: false, loading:true });
        
        if (this.state.resposta1 === -1 || this.state.resposta2 === -1) {

            this.setState({ loading: false, mensagemEnviada: false, mensagemAvisoSelecao: true});
        }
        else {
            let origem = ('pwhatsapp' in this.state.parametrosURL) && (this.state.parametrosURL.pwhatsapp == "true") ? 'WhatsApp' : 'Principal';
            let loja = this.isParamFarmaGuapi() ? 'Guapi' : this.isParamFarmaSaoJose() ? 'SaoJose' : 'Matriz';
            
            let resposta = [{
                Loja: loja,
                IdResposta: this.state.resposta1.split("-")[1].toString(),
                IdPergunta: this.state.resposta1.split("-")[0].toString(),
                OrigemResposta: origem
            }, {
                Loja: loja,
                IdResposta: this.state.resposta2.split("-")[1].toString(),
                IdPergunta: this.state.resposta2.split("-")[0].toString(),
                OrigemResposta: origem
            }];


            await fetch('PesquisaSatisfacao',
                {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body:
                        JSON.stringify(resposta)


                });

            this.setState({ loading: false, mensagemEnviada: true });
            //alert('Obrigado por compartilhar a sua opnião!');

        }
        
    }
}
