import React, { useState } from 'react';
import './Combobox.css';
function Combobox(props) {
    const { options, selectedValue, onSelectedValueChange } = props;

    const handleSelectChange = (event) => {
        onSelectedValueChange(event.target.value);
    }

    const defaultText = props.textonull === undefined ? "Selecione uma opção" : props.textonull;

    return (
        <select className="my-select" value={selectedValue} onChange={handleSelectChange}>
            <option value="">{defaultText}</option>
            {options.map((option) => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
    );
}

export default Combobox;